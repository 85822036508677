
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { getPanel, searchEncounters } from "@/api/encounter.api";
import { addClaim } from "@/api/claim.api";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  items: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  panel: any;
}
export default defineComponent({
  name: "EncountersTable",
  components: {},
  setup() {
    const router = useRouter();
    const orderList = [
      { name: "Encounter Id", key: "encounterId" },
      { name: "Provider", key: "providerId" },
      { name: "Patient", key: "patientId" },
      { name: "Facility", key: "facilityId" },
      { name: "AdmissionDate", key: "admissionDate" },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      orderBy: "encounterId",
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      panel: {},
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      await getAll();
    });

    async function createClaim(encounter) {
      let newClaim = {
        claimId: "",
        typeOfBill: "",
        admissionType: "",
        admissionSource: "",
        dischargeStatus: "",
        documentControlNumber: "",
        remarksField: "",
        encounterId: encounter.id,
        payerId: encounter.id,
      };

      await addClaim(newClaim);
    }

    async function search() {
      await getAll();
    }

    async function changePage(index) {
      if (data.pagination.currentPage == 1 && index < 0) return;
      if (
        data.pagination.currentPage == data.pagination.totalPages &&
        index > 0
      )
        return;

      data.pagination.currentPage = data.pagination.currentPage + index;
      await getAll();
    }

    async function selectPage(index) {
      data.pagination.currentPage = index;
      await getAll();
    }

    function getPatient(patientId) {
      return data.panel.patients.find((item) => {
        return item.id == patientId;
      });
    }

    function getProvider(providerId) {
      return data.panel.providers.find((item) => {
        return item.id == providerId;
      });
    }
    function getFacility(facilityId) {
      return data.panel.facilities.find((item) => {
        return item.id == facilityId;
      });
    }
    async function getAll() {
      data.panel = await getPanel(true);
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: [data.orderBy],
      };

      const res = await searchEncounters(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    function selectItem(item) {
      router.push({ name: "encounterPage", params: { encounterId: item.id } });
    }

    return {
      organizationId,
      data,
      selectItem,
      orderList,
      selectPage,
      search,
      changePage,
      getPatient,
      getProvider,
      getFacility,
      createClaim,
    };
  },
});
