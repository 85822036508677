
import { defineComponent, onMounted, ref } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EncountersTable from "@/modules/encounter/EncountersTable.vue";

export default defineComponent({
  name: "EncounterListPage",
  components: { EncountersTable },
  setup() {
    let organizationId = ref<string | null>("");

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Encounters List");
    });

    return {
      organizationId,
    };
  },
});
